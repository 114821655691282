import React from 'react'
import { graphql } from 'gatsby'
import { PageLayout } from '../components/Layout'
import Hero from '../components/Hero/Hero'
import { MapLayout } from '../components/Map'
import { PageContentAccordionList } from '../components/PageContentAccordionList'
import { Faq } from '../components/FAQ'
import { PaxCovidBanner } from '../components/PaxCovidBanner'
import { isContentfulType } from '../utils'

const Terminals = ({
  data: {
    content: {
      page: {
        hero,
        covidBannerLayout,
        rowsCollection: { items },
        faqSection,
      },
    },
  },
}) => {
  return (
    <PageLayout>
      {hero?.contentRef && (
        <>
          <Hero {...hero.contentRef} />
          {covidBannerLayout?.localisedContentReference?.header &&
            typeof sessionStorage !== 'undefined' &&
            sessionStorage.covidPaxBannerNotVisible !== 'true' && (
              <PaxCovidBanner
                {...covidBannerLayout?.localisedContentReference}
              />
            )}
        </>
      )}
      {items.map((item) => {
        return (
          <React.Fragment key={item.sys.id}>
            {isContentfulType(item.__typename, 'contentful_MapLayout') && (
              <MapLayout {...item} />
            )}
            {isContentfulType(
              item.__typename,
              'contentful_PageContentAccordionList',
            ) && <PageContentAccordionList {...item} />}
          </React.Fragment>
        )
      })}
      {faqSection?.itemsCollection?.items &&
        faqSection?.itemsCollection?.items.length !== 0 && (
          <Faq
            {...faqSection.itemsCollection}
            heading={faqSection?.heading}
            urlSlug={faqSection?.urlSlug?.slug}
            defaultExpanded={faqSection?.defaultExpanded ?? false}
          />
        )}
    </PageLayout>
  )
}

export const query = graphql`
  query TerminalsQuery(
    $id: String!
    $locale: String!
    $preview: Boolean = false
  ) {
    content: contentful {
      page: terminals(id: $id, locale: $locale, preview: $preview) {
        hero: heroLayout {
          ... on contentful_PaxHeroLayout {
            contentRef {
              ...PaxHeroContent
            }
          }
        }
        covidBannerLayout {
          localisedContentReference {
            ...CovidBanner
          }
        }
        rowsCollection(limit: 44) {
          items {
            ...MapLayout
            ...PageContentAccordionList
          }
        }
        faqSection {
          ...FaqFragment
        }
      }
    }
  }
`

export default Terminals
